<template>
	<div>
		<b-card no-body class="p-1">
			<div class="mb-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page ? start_page : 0 }} a {{ to_page ? to_page : 0 }} de
								{{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>

					<b-row>
						<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
							<label>Mostrar</label>
							<v-select
								v-model="perpage"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label class="mr-2">registros</label>
							<feather-icon
								class="cursor-pointer"
								icon="RefreshCcwIcon"
								size="20"
								@click="refreshTable"
							/>
						</b-col>

						<!-- Search -->
						<b-col cols="12" xl="8" class="mt-1">
							<div class="container-btns-page-specimens d-flex justify-content-end">
								<div class="d-flex">
									<b-form-input
										v-model="search_input"
										class="d-inline-block mr-1"
										placeholder="Buscar marcas..."
										debounce="500"
										@keyup.enter="$refs['table-brands'].refresh()"
									/>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="fields"
					sticky-header="40vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-brands"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>

					<template #cell(logotype)="data">
						<ViewImage :specimen="data.item" />
					</template>

					<template #cell(name)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span class="d-code">{{ data.item.name }}</span>
						</div>
					</template>

					<template #cell(description)="data">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span class="d-code">
								{{ data.item.description ? data.item.description : "---" }}
							</span>
						</div>
					</template>

					<template #cell(location)="{ item }">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div v-if="item.country_name">
								<strong>Pais:</strong>
								{{ item.country_name }}
							</div>
							<div v-if="item.department_name">
								<strong>Dpto:</strong>
								{{ item.department_name }}
							</div>
							<div v-if="item.province_name">
								<strong>Provincia:</strong>
								{{ item.province_name }}
							</div>
							<div v-if="item.district_name">
								<strong>Distrito:</strong>
								{{ item.district_name }}
							</div>
						</div>
					</template>
					<!-- <template #cell(status)="data" class="text-center">
						<div class="d-flex d-code justify-content-center align-items-center">
							<span
								:class="`d-code clasStatus ${
									data.item.status === 'inactive' ? 'inactives' : 'actives'
								} `"
							>
								{{ data.item.status }}
							</span>
						</div>
					</template> -->

					<template #cell(created_by)="data">
						<div class="d-flex d-code justify-content-center flex-column align-items-center">
							<span>{{ data.item.created_by.name }}</span>
							<span>{{ data.item.created_by.date | myGlobalDay }}</span>
						</div>
					</template>
					<template #cell(actions)="data">
						<div class="d-flex d-code justify-content-center">
							<b-button @click="openEditModal(data.item)" variant="warning" class="btn-icon ml-1 btn-sm">
								<feather-icon icon="EditIcon" />
							</b-button>

							<b-button
								@click="deleteBrands(data.item.id, data.item.name)"
								variant="danger"
								class="btn-icon ml-1 btn-sm"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</div>
		</b-card>

		<BrandsModal
			v-if="showModalBrands"
			:info="form"
			@close="showModalBrands = false"
			@registered="reset(), (showModalBrands = false)"
		/>
	</div>
</template>
<script>
import Fields from "@/views/brain/administrative/views/branch/data/fields-data.js"
import BrandsModal from "@/views/brain/administrative/views/branch/components/BrandsModal.vue"
import BrandsService from "@/views/brain/administrative/views/branch/services/brands.service.js"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	components: {
		BrandsModal,
		ViewImage,
	},
	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			fields: Fields,
			search_input: "",
			showModalBrands: false,
			initial_form: {
				id: null,
				name: null,
				description: null,
				logotype: null,
				country_id: null,
				district_id: null,
				department_id: null,
				province_id: null,
			},
			form: {
				id: null,
				name: null,
				description: null,
				logotype: null,
				country_id: null,
				district_id: null,
				department_id: null,
				province_id: null,
			},
		}
	},
	methods: {
		// openModalProvider() {
		// 	this.showModalProvider = !this.showModalProvider
		// },
		createBrands() {
			this.form = { ...this.initial_form }
			this.showModalBrands = true
		},
		async deleteBrands(id, name) {
			const confirm = await this.showConfirmSwal({
				text: `Eliminar la marca ${name}`,
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await BrandsService.deleteBrands(id)
				this.showSuccessToast(deleted.data.message)
				this.reset()
			} catch (error) {
				this.showErrorSwal(s, error.response?.data)
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input,
				page: ctx.currentPage,
				perpage: ctx.perPage,
			}
			try {
				const { data } = await BrandsService.getBrands(params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		refreshTable() {
			this.$refs["table-brands"].refresh()
		},

		reset() {
			this.$refs["table-brands"].refresh()
		},

		openModal() {
			this.showModalProvider = true
		},
		openEditModal(data) {
			const copy = JSON.parse(JSON.stringify(data))
			this.form = { ...copy }
			this.showModalBrands = true
		},
	},
}
</script>

<style scoped>
.image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.clasStatus {
	padding: 0.3rem 0.5rem;
	border-radius: 0.358rem;
	color: #fff;
	font-size: 85%;
	font-weight: 600;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out, background 0s, border 0s;
}

.actives {
	background-color: #28c76f;
}

.inactives {
	background-color: #ea5455;
}
</style>
